import { Injector, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import {
  AuthModule,
  BacklogModule,
  CartHttpService,
  CartLocalService,
  CartModule,
  CartService,
  CatalogModule,
  CloudinaryModule,
  CmsUiDynamicContentService,
  CoreModule,
  CrudModule,
  DownloadModule,
  ElasticsearchModule,
  ErrorModule,
  FactoryAttestationModule,
  FavoriteItemQuery,
  FavoriteModule,
  GoogleTagManagerAdapterModule,
  InjectorClass,
  PageBreadcrumbResolver,
  PageMetaModule,
  ParameterModule,
  SalesCreditNoteModule,
  SalesDocumentModule,
  SalesOfferModule,
  SalesOrderModule,
  ScannerAdapterAbstract,
  ScannerModule,
  SearchService,
  SentryModule,
  SteelModule,
  StrapiCmsModule,
} from '@lobos/library';
import { environment } from '../environments/environment';
import { HookHandlerModule } from './hooks/hook-handler/hook-handler.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { GermanDateAdapter } from './services/date/german-date.adapter';
import { BrowserStateInterceptor } from './interceptors/browser-state-interceptor';
import { Platform } from '@angular/cdk/platform';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { ZbarAdapter, ZbarConfig, ZbarSymbologyEnum } from '@lobos/scanner-adapter-zbar';
import { RouterModule } from '@angular/router';
import { TranslocoRootModule } from './transloco-root.module';
import { PipesModule } from './pipes/pipes.module';
import { AppRoutingModule } from './app-routing.module';
import { HookTriggerModule } from './hooks/hook-trigger/hook-trigger.module';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LabelPrintModule, MapperAbstract, TemplateRendererFactory } from '@lobos/label-print';
import { CookieModule } from 'ngx-cookie';
import { MarkdownModule } from 'ngx-markdown';
import { ToastrModule } from 'ngx-toastr';
import { WeylandCartLocalService } from './services/cart/cart-local.service';
import { WeylandCartHttpService } from './services/cart/cart-http.service';
import { WeylandCartService } from './services/cart/cart.service';
import { WeylandFavoriteMapper } from './services/label-print/mapper/weyland-favorite.mapper';
import { Weyland3x8BorderQrRenderer } from './services/label-print/renderer/weyland_3x8_border_qr.renderer';
import { WeylandFavoriteItemQuery } from './services/favorite/favorite-item.query';
import { WeylandPageBreadcrumbResolver } from './features/cms/page-breadcrumb.resolver';
import { WeylandCmsService } from './services/cms/weyland-cms.service';
import { WeylandSearchService } from './services/search/weyland-search.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    ErrorModule.forRoot({
      showOutput: !environment.production || environment.debugging,
      disableErrorHandler: environment.debugging,
    }),
    CoreModule.forRoot({
      baseUrl: environment.host,
      branchKey: environment.api.branchKey,
      webshopId: environment.api.webshopId,
      useConsent: true,
    }),
    SentryModule.forRoot({
      active: environment.sentry.active,
      debug: environment.debugging,
      environment: environment.name,
      dsn: environment.sentry.dsn,
      release: '1.0.0', // @todo
    }),
    HookHandlerModule.forRoot(),
    ElasticsearchModule.forRoot({
      availableLanguages: ['de', 'en'],
      defaultLanguage: environment.defaultLocale,
    }),
    ParameterModule,
    SteelModule,
    CartModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    FavoriteModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    AuthModule.forRoot({
      unauthorizedRoute: environment.auth.routes.login,
      authorizedRoute: '/',
      apiUrl: environment.apiUrl,
    }),
    SalesOfferModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    SalesOrderModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    SalesCreditNoteModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    AppRoutingModule,
    TranslocoRootModule,
    TranslocoLocaleModule,
    TranslocoMessageFormatModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    RouterModule,
    ComponentsModule,
    PipesModule,
    MatNativeDateModule,
    LabelPrintModule.forRoot({
      logoPath: environment.labelPrint.logoPath,
      images: environment.images,
      mapper: { provide: MapperAbstract, useClass: WeylandFavoriteMapper },
    }),
    ScannerModule.forRoot<ZbarConfig>({
      adapter: { provide: ScannerAdapterAbstract, useClass: ZbarAdapter },
      scanFrequency: 750,
      adapterConfig: {
        engineLocation: 'assets/',
        symbology: [ZbarSymbologyEnum.EAN13, ZbarSymbologyEnum.QR],
      },
    }),
    PageMetaModule,
    GoogleTagManagerAdapterModule.forRoot({ id: environment.tracking.gtm }),
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    HookTriggerModule.forRoot(),
    CookieModule.forRoot(),
    MarkdownModule.forRoot(),
    SalesDocumentModule.forRoot({
      apiUrl: environment.apiUrl,
      useArchive: false,
      pageSizeOptions: [5, 10, 25],
    }),
    StrapiCmsModule.forRoot({
      apiUrl: environment.cms.apiUrl,
    }),
    CloudinaryModule.forRoot({
      active: true,
      cloudName: environment.images.cloudinary.cloudName,
      fetchImageUrl: environment.images.cloudinary.fetchImageUrl,
    }),
    CatalogModule.forRoot({
      apiUrl: environment.apiUrl,
      pageIndex: 0,
      pageSize: 36,
      sortBy: 'relevance',
      viewMode: 'grid',
    }),
    BacklogModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    FactoryAttestationModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    ToastrModule.forRoot({ positionClass: 'toast-top-right', progressBar: true }),
    DownloadModule.forRoot({ fileDownloadUrl: environment.apiUrl, pageSizeOptions: [] }),
    CrudModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
  ],
  providers: [
    {
      provide: NG_ENTITY_SERVICE_CONFIG,
      useValue: { baseUrl: environment.host + environment.apiUrl },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'de' },
    { provide: DateAdapter, useClass: GermanDateAdapter, deps: [MAT_DATE_LOCALE, Platform] },
    { provide: CartService, useClass: WeylandCartService },
    { provide: CartHttpService, useClass: WeylandCartHttpService },
    { provide: CartLocalService, useClass: WeylandCartLocalService },
    { provide: FavoriteItemQuery, useClass: WeylandFavoriteItemQuery },
    { provide: PageBreadcrumbResolver, useClass: WeylandPageBreadcrumbResolver },
    { provide: CmsUiDynamicContentService, useClass: WeylandCmsService },
    { provide: SearchService, useClass: WeylandSearchService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    labelPrintTemplateRendererFactory: TemplateRendererFactory,
    injector: Injector,
    weyland3x8BorderQrRenderer: Weyland3x8BorderQrRenderer,
  ) {
    InjectorClass.set(injector);
    labelPrintTemplateRendererFactory.register(weyland3x8BorderQrRenderer);
  }
}
