import { Injectable } from '@angular/core';
import { Order } from '@datorama/akita';
import { AkitaFiltersPlugin } from 'akita-filters-plugin';
import { SalesOffer } from '../model/sales-offer.model';
import { SalesOfferState } from '../store/sales-offer.store';
import { SalesOfferQuery } from '../query/sales-offer.query';

@Injectable()
export class SalesOfferFilterService<T extends SalesOffer> extends AkitaFiltersPlugin<SalesOfferState<T>> {
  constructor(protected offerQuery: SalesOfferQuery<T>) {
    super(offerQuery, { filtersStoreName: 'sales-offer-filter' });
    this.setFilter({
      id: 'shtBlocked',
      value: 0,
      predicate: (entity: T) => {
        return entity.shtBlocked === 0;
      },
    });
    const fromDate = new Date();
    fromDate.setMonth(fromDate.getMonth() - 3);
    this.setFilter({
      id: 'dtEntryDate[gte]',
      value: fromDate.valueOf(),
      predicate: (entity: T) => {
        return entity.dtEntryDate >= fromDate.valueOf();
      },
    });
    this.setFilter({
      id: 'intPageIndex',
      value: 1,
      predicate: (_) => true,
    });
    this.setFilter({
      id: 'intPageSize',
      value: 10,
      predicate: (_) => true,
    });
  }

  /**
   * Sets orderBy
   */
  setOrderBy(by: any, order: string | '+' | '-') {
    this.setSortBy({
      sortBy: by,
      sortByOrder: order === '+' ? Order.ASC : Order.DESC,
    });
  }
}
