import { ID } from '@datorama/akita';
import { Customer } from '../../customer';
import { Contact } from '../../contact';

export interface User {
  /** user contact id */
  lngContactID: ID | number;
  /** user last login date */
  dtLastLogin: Date | number;
  /** user registration date */
  dtRegistration: Date | number;
  /** user customer id */
  lngCustomerID: number;
  /** user role id */
  shtRoleID: number;
  /** user email */
  sEmail: string;
  /** user first name */
  sFirstName: string;
  /** user form of adress (private or business) */
  sFormOfAdress: string;
  /** user last name */
  sLastName: string;
  /** user mobile phone */
  sMobilePhone: string;
  /** user phone */
  sPhone: string;
  /** user login name */
  sUserName: string;
  /** user language id */
  shtLanguageID: number;
  /** user active status */
  bActive: boolean;
  /** langauge iso code */
  sLanguageIsoCode: string;
  /** active favorite list ID */
  gActiveBookmarkListID: ID | string;
  /** active cart ID */
  lngActiveCartID: ID | number;
  /** user stock level (Lagerlevel)**/
  shtStockLevel: number;
  shtP48AGB: number;
  lngP48SalesRepID: number;
  oP48SalesRepForUser: User;
  sP48SalesRepForUser: string;
  bP48CustomerHasFactoryAttestationFlat: boolean;
  bP48CustomerIsAllowedToOrder: boolean;
  oCustomer: Customer;
  oContact: Contact;
  oP48APIAuthorizationGroup?: P48APIAuthorizationGroup;
  oNewsletterCharacteristicIDs: number[];
  shtP48ShowUserManagement: number;
}

export interface P48APIAuthorizationGroup {
  lngAPIAuthorizationGroupID: ID | number;
  oP48APIAuthorizationGroupAssignList: P48APIAuthorizationGroupAssign[];
  sRoleName: string;
  shtP48ShowUserManagement: number;
  shtPriceLevel: number;
  shtShowOpenItems: number;
  shtStockLevel: number;
}

export interface P48APIAuthorizationGroupAssign {
  bCRUDCreate: boolean;
  bCRUDDelete: boolean;
  bCRUDRead: boolean;
  bCRUDUpdate: boolean;
  sAPIAuthorizationID: string;
  sAPIAuthorizationName: string;
  shtAPIAuthorizationSystem: number;
  shtAPIAuthorizationType: number;
}

export function createUser({
  lngContactID = undefined,
  dtLastLogin = undefined,
  dtRegistration = new Date().valueOf(),
  lngCustomerID = undefined,
  shtRoleID = 0,
  sEmail = '',
  sFirstName = '',
  sFormOfAdress = '',
  sLastName = '',
  sMobilePhone = '',
  sPhone = '',
  sUserName = '',
  shtLanguageID = 1,
  bActive = true,
  sLanguageIsoCode = '',
  gActiveBookmarkListID = undefined,
  lngActiveCartID = undefined,
  sP48SalesRepForUser = '',
  oP48SalesRepForUser = undefined,
  bP48CustomerHasFactoryAttestationFlat = undefined,
  bP48CustomerIsAllowedToOrder = undefined,
}: Partial<User>) {
  return {
    lngContactID,
    dtLastLogin,
    dtRegistration,
    lngCustomerID,
    shtRoleID,
    sEmail,
    sFirstName,
    sFormOfAdress,
    sLastName,
    sMobilePhone,
    sPhone,
    sUserName,
    shtLanguageID,
    bActive,
    sLanguageIsoCode,
    gActiveBookmarkListID,
    lngActiveCartID,
    sP48SalesRepForUser,
    oP48SalesRepForUser,
    bP48CustomerHasFactoryAttestationFlat,
    bP48CustomerIsAllowedToOrder,
  } as User;
}
