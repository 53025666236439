import { Injectable } from '@angular/core';
import { lastValueFrom, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LogService } from '../../../../core';
import { PageTracking } from '../../../models/page-tracking';
import { TrackingAdapterInterface } from '../../../models/tracking-adapter.interface';
import { GoogleTagManagerEvent } from '../models/google-tag-manager.event';
import { GoogleTagManagerLoaderService } from './google-tag-manager-loader.service';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerAdapter implements TrackingAdapterInterface {
  constructor(private gtmLoaderService: GoogleTagManagerLoaderService, private logService: LogService) {}

  /**
   * Track events to GTM
   *
   * @see https://developers.google.com/tag-manager/ecommerce-ga4 for ecommerce events
   * @see https://support.google.com/analytics/answer/9267735?hl=en for recommended events
   */
  public async trackEvent<T extends GoogleTagManagerEvent>(event: T): Promise<void> {
    this.logService.log('GTM trackEvent', event);
    await this.pushTag({
      event: event['name'],
      ...event['payload'],
    });
  }

  /**
   * GTM page tracking in the backend is very unreliable. Events are fired multiple times
   * and pages are tracked with different page titles (through timing issues)
   *
   * @since 26.10.2021 the virtualPageview is the way to go
   * @see https://www.analyticsmania.com/post/single-page-web-app-with-google-tag-manager/ for other options
   */
  public async trackPage<T extends PageTracking>(page: T): Promise<void> {
    this.logService.log('GTM trackPage', page);
    await this.pushTag({
      event: 'virtualPageview',
      pageUrl: page.url,
      pageTitle: page.title,
    });
  }

  private async pushTag(tag: GoogleTagManagerEvent): Promise<void> {
    await lastValueFrom(
      (tag['ecommerce'] ? this.gtmLoaderService.pushTag({ ecommerce: null }) : of(undefined)).pipe(
        switchMap(() => this.gtmLoaderService.pushTag(tag)),
      ),
    );
  }
}
