import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { map, tap } from 'rxjs/operators';
import { SteelFigureImage } from '../models/steel-figure-image.model';
import { SteelFigureImageStore } from '../store/steel-figure-image.store';
import { Figure } from '../models/steel-configuration.model';
import { forkJoin, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SteelFigureImageService {
  constructor(private steelFigureImageStore: SteelFigureImageStore, private http: HttpClient) {}

  get(sFigureID: ID | string) {
    const encodedFigureImageID = encodeURIComponent(sFigureID);
    return this.http.get<SteelFigureImage>(`/p48apihost/steel-figure-image/${encodedFigureImageID}`, {}).pipe(
      tap((oFigureImage: SteelFigureImage) => {
        this.steelFigureImageStore.upsert(sFigureID, oFigureImage);
      }),
    );
  }

  getImagesByFigureList(figureList: Figure[]): Observable<Figure[]> {
    const imageObservables = figureList.map((figure: Figure) => this.get(figure.sFigureID));
    return forkJoin(imageObservables).pipe(map((images: SteelFigureImage[]) => this.mapImageToFigure(images, figureList)));
  }

  mapImageToFigure(images: SteelFigureImage[], figureList: Figure[]): Figure[] {
    return figureList.map((figure: Figure) => {
      return {
        ...figure,
        binTakePositionImage: images.find((image: SteelFigureImage) => image.sFigureID === figure.sFigureID)?.binTakePositionImage,
      };
    });
  }
}
