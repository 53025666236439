import { environmentCommon } from '../environments/environment.common';

export const environment = {
  ...environmentCommon,
  ssrPort: 4100,
  loadTranslationFromServer: true,
  host: 'https://testshop.ferrochema.at',
  api: {
    ...environmentCommon.api,
    host: 'https://testshop.ferrochema.at',
    branchKey: 'W102',
  },

  cms: {
    ...environmentCommon.cms,
    host: 'http://localhost:1339',
  },

  sentry: {
    ...environmentCommon.sentry,
    dsn: 'https://ae01a6a44ac04d6481d960956bd06a91@o920539.ingest.sentry.io/6105512',
    active: true,
  },
  name: 'TESTING',
  registerDocLink: 'https://docs.google.com/forms/d/e/1FAIpQLSfIFJY6DnCrhtoktEYFHWQj5jLgUpxhTUq22phPnqPk9Djmow/viewform',
  labelPrint: {
    logoPath: '/assets/images/logo_W102.png',
  },
};
