import { CART_CONFIG, CartConfig, CartHttpService, SteelCreateCartItemInterface } from '@lobos/library';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { WeylandCartAdapterInterface } from '../../interfaces/cart-adapter.interface';
import { WeylandSteelCartItem } from '../../interfaces/cart-item.model';
import { WeylandCartHeader } from '../../interfaces/cart-header.model';

@Injectable({ providedIn: 'root' })
export class WeylandCartHttpService
  extends CartHttpService<WeylandCartHeader, WeylandSteelCartItem, SteelCreateCartItemInterface>
  implements WeylandCartAdapterInterface
{
  constructor(protected override http: HttpClient, @Inject(CART_CONFIG) protected override cartConfig: CartConfig) {
    super(http, cartConfig);
  }

  getCartTypeId(header: WeylandCartHeader): Observable<number> {
    return of(header.shtPL1P48ProductTypeID);
  }

  submitOci(cartHeader: WeylandCartHeader): Observable<unknown> {
    return this.http.put(`${this.cartConfig.apiUrl}/so-carts/${cartHeader.lngOrderID}/submit-oci`, {});
  }
}
