import { Inject, Injectable } from '@angular/core';
import { filter, tap } from 'rxjs/operators';
import { ElasticSearchService, SearchResponse } from '../../core';
import { CatalogStore } from '../store/catalog.store';
import { buildCatalgQueryByParentUrlPath, buildCatalogByLevelQuery, buildCatalogQuery } from '../utils/catalog.util';
import { CATALOG_CONFIG, CatalogConfig } from '../config/catalog.config';
import { Catalog } from '../model/catalog.model';

@Injectable({ providedIn: 'root' })
export class CatalogService<T extends Catalog = Catalog> {
  /**
   * Creates an instance of catalog service.
   */
  constructor(
    protected store: CatalogStore,
    protected elasticSearch: ElasticSearchService,
    @Inject(CATALOG_CONFIG) protected catalogConfig: CatalogConfig,
  ) {}

  getMenuTree() {
    const query = buildCatalogQuery();
    return this.elasticSearch
      .execute<SearchResponse<T>>(this.catalogConfig.apiUrl + '/es/search', {
        index: this.elasticSearch.localizedIndex('category'),
        query: JSON.stringify(query),
      })
      .pipe(
        filter((data) => !!data?.hits?.hits),
        tap((response) => {
          const catalogs = response?.hits?.hits.map((group) => {
            return { ...group._source, id: group._source.lngGroup };
          });
          this.store.upsertMany(catalogs);
        }),
      );
  }

  getCatalogLevelByParentGroupId(groupdId: string) {
    const query = buildCatalogByLevelQuery(groupdId);
    return this.elasticSearch
      .execute<SearchResponse<Catalog>>(this.catalogConfig.apiUrl + '/es/search', {
        index: this.elasticSearch.localizedIndex('category'),
        query: JSON.stringify(query),
      })
      .pipe(
        tap((response) => {
          const categories = response?.hits?.hits.map((group) => {
            return { ...group._source, id: group._source.lngGroup };
          });
          this.store.upsertMany(categories);
        }),
      );
  }

  getCatalogLevelByParentUrlPath(parentUrlPath: string) {
    const query = buildCatalgQueryByParentUrlPath(parentUrlPath);
    return this.elasticSearch
      .execute<SearchResponse<Catalog>>(this.catalogConfig.apiUrl + '/es/search', {
        index: this.elasticSearch.localizedIndex('category'),
        query: JSON.stringify(query),
      })
      .pipe(
        tap((response) => {
          const categories = response?.hits?.hits.map((group) => {
            return { ...group._source, id: group._source.sUrlPath };
          });
          this.store.upsertMany(categories);
        }),
      );
  }

  buildCatalogTree(items: T[], id = 0): T[] {
    return items
      .filter((item) => item.lngParentGroup === id)
      .map((item) => ({
        ...item,
        children: this.buildCatalogTree(items, item.lngGroup as number),
      }));
  }
}
