import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { DOWNLOAD_CONFIG, DownloadConfig } from './config/download.config';

@NgModule({
  imports: [CommonModule, TranslocoModule],
})
export class DownloadModule {
  static forRoot(config: DownloadConfig): ModuleWithProviders<DownloadModule> {
    return {
      ngModule: DownloadModule,
      providers: [{ provide: DOWNLOAD_CONFIG, useValue: config }],
    };
  }
}
