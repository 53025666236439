import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ACCESS_ID, AuthService, User } from '@lobos/library';
import { LoginDialogService } from '../../../../services/login/login-dialog.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileMenuComponent {
  isLoggedIn$: Observable<boolean>;
  accessId: typeof ACCESS_ID = ACCESS_ID;
  status: boolean = false;
  environment = environment;

  currentUser$: Observable<User | undefined> = this.authService.authUser$;

  constructor(
    private translocoService: TranslocoService,
    private authService: AuthService,
    private loginDialogService: LoginDialogService,
    private router: Router,
  ) {
    this.isLoggedIn$ = this.authService.isLoggedIn$;
  }

  openLoginDialog() {
    this.loginDialogService.open();
  }

  public async logout() {
    await this.authService.logout();
    await this.router.navigate(['/']);
  }

  clickEvent() {
    this.status = !this.status;
  }
}
