import {
  CartGuestService,
  CartHeader,
  CartHeaderQuery,
  CartHeaderStore,
  CartHttpService,
  CartItemQuery,
  CartItemStore,
  CartLocalService,
  CartService,
} from '../../cart';
import { SteelCartItem } from '../models/steel-cart-item.model';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../auth';
import { UserService } from '../../user';
import { Injectable } from '@angular/core';
import { SteelCreateCartItemInterface } from '../interfaces/steel-create-cart-item.interface';
import { ParameterQuery } from '../../parameter';

@Injectable({ providedIn: 'root' })
export class SteelCartService<T extends CartHeader, R extends SteelCartItem, S extends SteelCreateCartItemInterface> extends CartService<
  T,
  R,
  S
> {
  constructor(
    protected override transloco: TranslocoService,
    protected override translocoLocale: TranslocoLocaleService,
    protected override cartHttpService: CartHttpService<T, R, S>,
    protected override cartLocalService: CartLocalService<T, R, S>,
    protected override cartGuestService: CartGuestService<T, R, S>,
    protected override authService: AuthService,
    protected override cartHeaderStore: CartHeaderStore<T>,
    protected override cartItemStore: CartItemStore<R>,
    protected override cartItemQuery: CartItemQuery<R>,
    protected override cartHeaderQuery: CartHeaderQuery<T>,
    protected override http: HttpClient,
    protected override userService: UserService,
    protected override paramQuery: ParameterQuery,
  ) {
    super(
      transloco,
      translocoLocale,
      cartHttpService,
      cartLocalService,
      cartGuestService,
      authService,
      cartHeaderStore,
      cartItemStore,
      cartItemQuery,
      cartHeaderQuery,
      http,
      userService,
      paramQuery,
    );
  }
}
