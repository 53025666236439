import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RoleQuery } from '../query/role.query';
import { Role } from '../model/role.model';

@Pipe({
  name: 'role',
})
export class RoleNamePipePipe implements PipeTransform {
  /**
   * Creates an instance of role name pipe.
   * @param roleQuery the query used to get role names
   */
  constructor(private roleQuery: RoleQuery<Role>) {}

  /**
   * Transforms a role id in a role name
   * @param value the role id value
   * @returns role name
   */
  transform(value: number): Observable<string> {
    return this.roleQuery.selectAll().pipe(
      map((roles) => {
        if (roles.length > 0 && value) {
          return roles.find((role) => role.lngAPIAuthorizationGroupID === value)!.sRoleName;
        } else {
          return '';
        }
      }),
    );
  }
}
