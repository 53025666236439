import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { FileTreeState, FileTreeStore } from '../store/file-tree.store';
import { DOWNLOAD_CONFIG, DownloadConfig } from '../config/download.config';
import { FileTree } from '../models/file-tree.model';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';

/**
 * A service to manage file-tree entities,
 */
@Injectable({ providedIn: 'any' })
export class FileTreeService<T extends FileTree> extends NgEntityService<FileTreeState<T>> {
  constructor(protected override store: FileTreeStore<T>, @Inject(DOWNLOAD_CONFIG) protected downloadConfig: DownloadConfig) {
    super(store);
  }

  downloadFile(node: T) {
    const params = new HttpParams().append('sPath', node.sPath);
    return this.getHttp()
      .get<T[]>(this.downloadConfig.fileDownloadUrl + '/download-file', {
        params,
        responseType: 'blob' as 'json',
      })
      .subscribe((res: T[]) => {
        this.saveFileAs(res, node.sName, node.sType);
      });
  }

  downloadTree(): Observable<T[]> {
    return this.getHttp().get<T[]>(this.downloadConfig.fileDownloadUrl + '/download-tree');
  }

  saveFileAs(fileBlob: any, fileName: string, fileExtension: string) {
    const fileType = 'application/' + fileExtension;
    const file = new Blob([fileBlob], { type: fileType });
    saveAs(file, fileName);
  }
}
