import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DebounceClickDirective } from './debounce-click.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [DebounceClickDirective],
  exports: [DebounceClickDirective],
})
export class DebounceClickModule {}
