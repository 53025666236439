import { Pipe, PipeTransform } from '@angular/core';
import { iif, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ShippingTypeQuery } from '../query/shipping-type.query';
import { ShippingType } from '../model/shipping-type.model';
import { ShippingTypeService } from '../services/shipping-type.service';
import { ID } from '@datorama/akita';

@Pipe({
  name: 'shippingCondition',
})
export class ShippingConditionPipe implements PipeTransform {
  constructor(
    protected shippingTypeQuery: ShippingTypeQuery<ShippingType>,
    protected shippingTypeService: ShippingTypeService<ShippingType>,
  ) {}

  transform(value: number, lngOrderID?: ID): Observable<string> {
    return of(undefined).pipe(
      switchMap(() =>
        iif(
          () => this.shippingTypeQuery.hasEntity(value),
          this.shippingTypeQuery.selectEntity(value),
          iif(
            () => !!lngOrderID,
            this.shippingTypeService.getShippingTypes(lngOrderID!).pipe(switchMap(() => this.shippingTypeQuery.selectEntity(value))),
            of(undefined),
          ),
        ),
      ),
      map((shippingType: ShippingType | undefined) => {
        if (shippingType) {
          return shippingType.sName;
        } else {
          return 'Error shipping-Type not found!';
        }
      }),
    );

    /*return this.shippingTypeQuery.selectEntity(value).pipe(
      map((shippingType: ShippingType | undefined) => {
        if (shippingType) {
          return shippingType.sName;
        } else {
          return 'Error shipping-Type not found!';
        }
      }),
    );*/
  }
}
