import {
  AuthService,
  Cart,
  CartGuestService,
  CartHeaderQuery,
  CartHeaderStore,
  CartHttpService,
  CartItemQuery,
  CartItemStore,
  CartLocalService,
  createCartHeader,
  ParameterQuery,
  SteelCartService,
  SteelCreateCartItemInterface,
  switchTap,
  User,
  UserService,
} from '@lobos/library';
import { combineLatestWith, iif, Observable, of, throwError } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, first, switchMap, withLatestFrom } from 'rxjs/operators';
import { ID } from '@datorama/akita';
import { WeylandCartHttpService } from './cart-http.service';
import { WeylandCartLocalService } from './cart-local.service';
import { MatDialog } from '@angular/material/dialog';
import { CartTypeMismatchModalComponent } from '../../components/cart-type-mismatch-modal/cart-type-mismatch-modal.component';
import { WeylandCartAdapterInterface } from '../../interfaces/cart-adapter.interface';
import { CartErrorTypeMismatch } from '../../interfaces/cart-errors';
import { WeylandSteelCartItem } from '../../interfaces/cart-item.model';
import { WeylandCartHeader } from '../../interfaces/cart-header.model';
import { WeylandSteelCreateCartItem } from '../../interfaces/steel-create-cart-item.interface';

@Injectable({ providedIn: 'root' })
export class WeylandCartService
  extends SteelCartService<WeylandCartHeader, WeylandSteelCartItem, SteelCreateCartItemInterface>
  implements WeylandCartAdapterInterface
{
  constructor(
    transloco: TranslocoService,
    translocoLocale: TranslocoLocaleService,
    cartHttpService: CartHttpService<WeylandCartHeader, WeylandSteelCartItem, SteelCreateCartItemInterface>,
    cartLocalService: CartLocalService<WeylandCartHeader, WeylandSteelCartItem, SteelCreateCartItemInterface>,
    cartGuestService: CartGuestService<WeylandCartHeader, WeylandSteelCartItem, SteelCreateCartItemInterface>,
    authService: AuthService,
    cartHeaderStore: CartHeaderStore<WeylandCartHeader>,
    cartItemStore: CartItemStore<WeylandSteelCartItem>,
    cartItemQuery: CartItemQuery<WeylandSteelCartItem>,
    cartHeaderQuery: CartHeaderQuery<WeylandCartHeader>,
    http: HttpClient,
    userService: UserService<User>,
    private weylandCartHttpService: WeylandCartHttpService,
    private weylandCartLocalService: WeylandCartLocalService,
    private dialog: MatDialog,
    protected override paramQuery: ParameterQuery,
  ) {
    super(
      transloco,
      translocoLocale,
      cartHttpService,
      cartLocalService,
      cartGuestService,
      authService,
      cartHeaderStore,
      cartItemStore,
      cartItemQuery,
      cartHeaderQuery,
      http,
      userService,
      paramQuery,
    );
  }

  getCartHeader(id: number | ID): Observable<WeylandCartHeader | undefined> {
    if (id === -1) {
      // todo: check for existing cart by guestCartID
      return of({
        ...createCartHeader({}),
        sPL1P48TPPhoneShipping: '',
        sPL1P48TPTransportRestriction: '',
        shtPL1P48ProductTypeID: 0,
      });
    }
    if (this.cartHeaderQuery.hasEntity(id)) {
      return this.cartHeaderQuery.selectEntity(id).pipe(first());
    }

    return this.iif<WeylandCartHeader[]>(
      this.cartHttpService.getCartHeaders(),
      this.cartLocalService.getCartHeaders(),
      this.cartGuestService.getCartHeaders(),
    ).pipe(switchMap(() => this.cartHeaderQuery.selectEntity(id).pipe(first())));
  }

  getCartTypeId(header: WeylandCartHeader): Observable<number> {
    return this.iif<number>(
      this.weylandCartHttpService.getCartTypeId(header),
      this.weylandCartLocalService.getCartTypeId(header),
      this.weylandCartHttpService.getCartTypeId(header),
    );
  }

  override createCartItem(cartItem: WeylandSteelCreateCartItem): Observable<Cart<WeylandCartHeader, WeylandSteelCartItem>> {
    const activeCartId = this.cartHeaderQuery.getActiveId();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.getCartHeader(activeCartId!).pipe(
      switchTap((header: WeylandCartHeader | undefined) =>
        of(header).pipe(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          withLatestFrom(this.getCartTypeId(header!)),
          switchMap(([header, typeId]) =>
            iif<any, any>(
              // if no type is set yet, or it is the same
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              () => !typeId || typeId === cartItem.oArticle.shtPL1P48ProductTypeID,
              // true, proceed normally
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              typeId === cartItem.oArticle.shtPL1P48ProductTypeID || !header?.lngOrderID //todo: needs fix for header === undefined
                ? of(header)
                : this.updateCartHeader({
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    ...header,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    shtPL1P48ProductTypeID: cartItem.oArticle.shtPL1P48ProductTypeID,
                  }),
              // false, throw error to create new cart first
              throwError({ code: 'cart-type-mismatch' } as CartErrorTypeMismatch),
            ),
          ),
        ),
      ),
      switchMap(() => super.createCartItem(cartItem)),
      catchError((error: CartErrorTypeMismatch) =>
        error.code === 'cart-type-mismatch' ? this.showResolveModal(cartItem) : throwError(error),
      ),
      first(),
    );
  }

  showResolveModal(item: WeylandSteelCreateCartItem): Observable<Cart<WeylandCartHeader, WeylandSteelCartItem>> {
    return this.dialog
      .open(CartTypeMismatchModalComponent, { data: { item } })
      .beforeClosed()
      .pipe(
        switchMap((result?: { create?: boolean }) =>
          iif<any, any>(
            // user chose not to create new cart
            () => !result?.create,
            // true
            of({ oSalesItemList: [item] } as Cart<WeylandCartHeader, WeylandSteelCartItem>),
            // false
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.createNewEmptyCart(item.oArticle.shtPL1P48ProductTypeID).pipe(switchMap(() => this.createCartItem(item))) as any,
          ),
        ),
      );
  }

  createNewEmptyCart(shtPL1P48ProductTypeID = 0): Observable<WeylandCartHeader> {
    return this.authService.authUser$.pipe(
      switchTap((user?: User) => (user ? of(undefined) : this.deleteCart(1))),
      switchMap((user?: User) =>
        user
          ? this.createEmptyList(user, shtPL1P48ProductTypeID)
          : this.createCartHeader({ ...createCartHeader({}), shtPL1P48ProductTypeID }),
      ),
      switchTap((header: WeylandCartHeader) => this.changeActiveCart(header.lngOrderID)),
      first(),
    );
  }

  override createEmptyList(user: User, shtPL1P48ProductTypeID = 0): Observable<WeylandCartHeader> {
    // when injecting transloco scope, it is the wrong one, so it is added hard-coded here
    const scope = 'cart';

    return this.transloco
      .selectTranslate(
        'domain.new-cart.name',
        { date: this.translocoLocale.localizeDate(Date.now(), undefined, { dateStyle: 'medium', timeStyle: 'short' }) },
        scope,
      )
      .pipe(
        switchMap((name: string) =>
          this.createCartHeader({
            ...createCartHeader({
              lngCustomerID: user.lngCustomerID,
              lngContactID: user.lngContactID as number,
              sCartName: name,
            }),
            shtPL1P48ProductTypeID: shtPL1P48ProductTypeID,
          }),
        ),
      );
  }

  submitOci(cartHeader: WeylandCartHeader): Observable<unknown> {
    return this.weylandCartHttpService.submitOci(cartHeader).pipe(
      combineLatestWith(this.authService.authUser$),
      switchMap(([_, user]) => {
        if (user) return this.activateRecentOrCreateNew(user);
        return of(undefined);
      }),
    );
  }
}
