import { Inject, Pipe, PipeTransform } from '@angular/core';
import { ImagePathHelperService } from '../services/image-path-helper.service';
import { CLOUDINARY_CONFIG, CloudinaryConfig } from '../config/cloudinary.config';

@Pipe({
  name: 'cloudinaryImagePath',
})
export class CloudinaryImagePathPipe implements PipeTransform {
  constructor(@Inject(CLOUDINARY_CONFIG) private cloudinaryConfig: CloudinaryConfig) {}

  transform(value: string): string {
    return ImagePathHelperService.getImagePath(value, this.cloudinaryConfig);
  }
}
