<ng-container *transloco="let t; scope: 'core'">
  <ng-container *ngIf="isLoggedIn$ | async">
    <div class="mobile-nav-header">
      <div class="bold" *ngIf="currentUser$ | async as user">
        <i class="icon-login icon-login-mobile"></i>{{ user.lngCustomerID }}, {{ user.sFirstName }} {{ user.sLastName }}
      </div>
      <i class="icon-close" (click)="closeMobileNav.emit()"></i>
    </div>

    <div class="mobile-content-fading">
      <ng-container *ngIf="currentUser$ | async as user">
        <div class="profile-menu-item">
          <a [routerLink]="'/profile/my-profile' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.profile") }}</span>
          </a>
        </div>
        <div class="profile-menu-item">
          <a [routerLink]="'/profile/carts' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.carts") }}</span>
          </a>
        </div>
        <div class="profile-menu-item">
          <a [routerLink]="'/profile/favorites' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.favlists") }}</span>
          </a>
        </div>
        <div class="profile-menu-item">
          <a [routerLink]="'/profile/shipping-addresses' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.addresses") }}</span>
          </a>
        </div>
        <div class="profile-menu-item" *ngIf="accessId.SHOW_HISTORICAL_DATA | hasAccess | async">
          <a [routerLink]="'/profile/sales-offer' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.offers") }}</span>
          </a>
        </div>
        <div class="profile-menu-item" *ngIf="accessId.SHOW_HISTORICAL_DATA | hasAccess | async">
          <a [routerLink]="'/profile/sales-order' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.orders") }}</span>
          </a>
        </div>
        <div class="profile-menu-item">
          <a [routerLink]="'/profile/sales-credit-notes' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.credits-notes") }}</span>
          </a>
        </div>
        <div class="profile-menu-item">
          <a [routerLink]="'/profile/backlog' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.backlog") }}</span>
          </a>
        </div>
        <div class="profile-menu-item" *ngIf="accessId.SHOW_USER_MANAGEMENT | hasAccess | async">
          <a [routerLink]="'/profile/users' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.users") }}</span>
          </a>
        </div>
        <div class="profile-menu-item">
          <a [routerLink]="'/profile/price-list-export' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.price-list-export") }}</span>
          </a>
        </div>
        <div class="profile-menu-item">
          <a [routerLink]="'/profile/download' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.download") }}</span>
          </a>
        </div>
        <div class="profile-menu-item" *ngIf="user.bP48CustomerHasFactoryAttestationFlat">
          <a [routerLink]="'/profile/factory-attestation' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.attestation") }}</span>
          </a>
        </div>
        <div class="profile-menu-item" *ngIf="user.lngP48SalesRepID || user.sP48SalesRepForUser">
          <a [routerLink]="'/profile/sales-rep' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.sales-rep") }}</span>
          </a>
        </div>
      </ng-container>
      <div class="profile-menu-item">
        <a (click)="logout()">
          <span>{{ t("core.header-nav.profile-menu.logout") }}</span>
        </a>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(isLoggedIn$ | async) === false">
    <div class="profile-menu-item">
      <a (click)="openLoginDialog()" class="btn btn--bright btn--wide text-centered default-font-sizes"
        ><i class="icon-login"></i> {{ t("core.header-nav.profile-menu.login") }}
      </a>
    </div>
    <div class="profile-menu-item register">
      <a target="_blank" [href]="environment.registerDocLink"><i class="icon-mail"></i> {{ t("core.header-nav.profile-menu.register") }}</a>
    </div>
  </ng-container>
</ng-container>
