import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import { UrlHelperService } from '../../core';
import { TrackingFactory } from '../../tracking';
import { JsonLd } from '../model/json-ld.interface';
import { PageMetaInterface } from '../model/page-meta.interface';
import { JsonLdService } from './json-ld.service';
import { LinkTagService } from './link-tag.service';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class PageMetaService {
  public static SUFFIX_SEPARATOR = '-';
  static readonly TRANSLOCO_SCOPE = 'shared';
  private initiallySet: boolean = false;
  private defaultMetadata: PageMetaInterface = {} as PageMetaInterface;

  constructor(
    @Inject(TRANSLOCO_SCOPE) private scope: any,
    private metaTagService: Meta,
    private linkTagService: LinkTagService,
    private titleService: Title,
    private jsonLdService: JsonLdService,
    private trackingFactory: TrackingFactory,
    private transloco: TranslocoService,
    private urlHelper: UrlHelperService,
  ) {
    combineLatest([
      this.transloco.selectTranslate('util-page-meta.default.title', undefined, PageMetaService.TRANSLOCO_SCOPE),
      this.transloco.selectTranslate('util-page-meta.default.description', undefined, PageMetaService.TRANSLOCO_SCOPE),
      this.transloco.selectTranslate('util-page-meta.default.author', undefined, PageMetaService.TRANSLOCO_SCOPE),
      this.transloco.selectTranslate('util-page-meta.default.keywords', undefined, PageMetaService.TRANSLOCO_SCOPE),
      this.transloco.selectTranslate('util-page-meta.default.type', undefined, PageMetaService.TRANSLOCO_SCOPE),
      this.transloco.selectTranslate('util-page-meta.default.image', undefined, PageMetaService.TRANSLOCO_SCOPE),
    ])
      .pipe(first(), untilDestroyed(this))
      .subscribe(async ([title, description, author, keywords, type, image]: string[]) => {
        this.defaultMetadata = { title, description, author, keywords, type, image };
        if (this.initiallySet) {
          return;
        }
        await this.updateMetadata(this.defaultMetadata, true, true);
      });

    this.linkTagService.add({ rel: 'alternate', hreflang: 'x-default', href: this.urlHelper.enforceAbsoluteUrl('') });
    for (const lang of this.transloco.getAvailableLangs()) {
      this.linkTagService.add({
        rel: 'alternate',
        hreflang: lang.toString(),
        href: this.urlHelper.enforceAbsoluteUrl(`/${lang}`),
      });
    }
  }

  public async updateMetadata(metadata: Partial<PageMetaInterface>, index = true, noTracking = false): Promise<void> {
    this.initiallySet = true;
    const pageMetadata: PageMetaInterface = {
      ...this.defaultMetadata,
      ...metadata,
      title: metadata.title
        ? `${metadata.title} ${PageMetaService.SUFFIX_SEPARATOR} ${this.defaultMetadata.title}`
        : this.defaultMetadata.title,
    };

    if (pageMetadata.canonical) {
      this.linkTagService.updateCanonicalTag(this.urlHelper.enforceAbsoluteUrl(pageMetadata.canonical));
    } else {
      this.linkTagService.removeCanonicalTag();
    }

    let metaTags: MetaDefinition[] = this.generateMetaDefinitions(pageMetadata);
    metaTags = [
      ...metaTags,
      { property: 'og:url', content: `${this.urlHelper.enforceAbsoluteUrl()}` },
      { name: 'robots', content: index ? 'index, follow' : 'noindex' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
    ];

    for (const metaTag of metaTags) {
      if (!metaTag.content) {
        this.metaTagService.removeTag(metaTag.name ? `name="${metaTag.name}"` : `property="${metaTag.property}"`);
        continue;
      }

      this.metaTagService.updateTag(metaTag);
    }

    this.titleService.setTitle(pageMetadata.title);

    if (metadata.jsonLd) {
      let jsonLd: JsonLd | JsonLd[] = metadata.jsonLd;
      if (!Array.isArray(jsonLd)) {
        jsonLd = [jsonLd];
      }
      jsonLd = jsonLd.map((jld: JsonLd) => this.jsonLdService.getObject(jld['@type'], jld, jld['@context']));

      this.jsonLdService.set(jsonLd);
    } else {
      this.jsonLdService.reset();
    }

    if (!noTracking) {
      await this.trackingFactory.trackPage({
        title: pageMetadata.title,
        url: this.urlHelper.enforceAbsoluteUrl(),
      });
    }
  }

  private generateMetaDefinitions(metadata: PageMetaInterface): MetaDefinition[] {
    return [
      { name: 'title', content: metadata.title },
      { property: 'og:title', content: metadata.title },

      { name: 'description', content: metadata.description ? metadata.description.replace(/<.*?>/g, ' ') : '' },
      { property: 'og:description', content: metadata.description ? metadata.description.replace(/<.*?>/g, ' ') : '' },

      { name: 'author', content: metadata.author },
      { property: 'og:author', content: metadata.author },

      { name: 'keywords', content: metadata.keywords },

      { property: 'og:type', content: metadata.type },

      {
        property: 'og:image',
        content: this.urlHelper.enforceAbsoluteUrl(metadata.image),
      },
    ];
  }
}
