import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { saveAs } from 'file-saver';
import { combineLatest, iif, merge, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, finalize, first, map, mergeMap, switchMap, take, takeUntil, tap, toArray } from 'rxjs/operators';
import { AuthService } from '../../auth';
import { Hook, HookReturnType, switchTap, WorkflowCreateHook, WorkflowDeleteHook, WorkflowUpdateHook } from '../../core';
import { ParameterQuery } from '../../parameter';
import { AddToCartEvent, GoogleTagManagerHelper, PurchaseEvent, RemoveFromCartEvent, TrackMethod } from '../../tracking';
import { User, UserService } from '../../user';
import { CartAdapterInterface } from '../model/cart-adapter.interface';
import { CartHeader, createCartHeader } from '../model/cart-header.model';
import { CartItem } from '../model/cart-item.model';
import { Cart } from '../model/cart.model';
import { CreateCartItemInterface } from '../model/create-cart-item.interface';
import { CartHeaderQuery } from '../query/cart-header.query';
import { CartItemQuery } from '../query/cart-item.query';
import { CartHeaderStore } from '../store/cart-header.store';
import { CartItemStore } from '../store/cart-item.store';
import { CartGuestService } from './cart-guest.service';
import { CartHttpService } from './cart-http.service';
import { CartLocalService } from './cart-local.service';

// @dynamic
@Injectable({ providedIn: 'root' })
export class CartService<T extends CartHeader, R extends CartItem, S extends CreateCartItemInterface>
  implements CartAdapterInterface<T, R, S>
{
  constructor(
    protected transloco: TranslocoService,
    protected translocoLocale: TranslocoLocaleService,
    protected cartHttpService: CartHttpService<T, R, S>,
    protected cartLocalService: CartLocalService<T, R, S>,
    protected cartGuestService: CartGuestService<T, R, S>,
    protected authService: AuthService,
    protected cartHeaderStore: CartHeaderStore<T>,
    protected cartItemStore: CartItemStore<R>,
    protected cartItemQuery: CartItemQuery<R>,
    protected cartHeaderQuery: CartHeaderQuery<T>,
    protected http: HttpClient,
    protected userService: UserService,
    protected paramQuery: ParameterQuery,
  ) {}

  initializeCarts(): Observable<T[]> {
    return this.authService.authUser$.pipe(
      tap(() => this.clearCartHeader()),
      switchMap((user: User | undefined) => this.setActiveId(user)),
      filter((id: number | ID) => !!id),
      switchMap((id: number | ID) => this.getCartById(id).pipe(catchError(() => of(undefined)))),
      switchMap(() => this.getCartHeaders()),
    );
  }

  getCartHeaders(): Observable<T[]> {
    this.cartHeaderStore.setLoading(true);
    return this.iif<T[]>(
      this.cartHttpService.getCartHeaders(),
      this.cartLocalService.getCartHeaders(),
      this.cartGuestService.getCartHeaders(),
    ).pipe(
      tap((carts: T[]) => this.cartHeaderStore.upsertMany(carts)),
      finalize(() => this.cartHeaderStore.setLoading(false)),
    );
  }

  getCartById(cartId: number | ID): Observable<Cart<T, R>> {
    return of(undefined).pipe(
      tap(() => {
        this.cartHeaderStore.setLoading(true);
        this.cartItemStore.setLoading(true);
      }),
      switchMap(() =>
        this.iif<Cart<T, R>>(
          this.cartHttpService.getCartById(cartId),
          this.cartLocalService.getCartById(cartId),
          this.cartGuestService.getCartById(cartId),
        ),
      ),
      tap((cart: Cart<T, R>) => {
        this.cartHeaderStore.upsert(cart.oSalesMaster.lngOrderID, cart.oSalesMaster);
        this.cartItemStore.upsertMany(this.generateCartItemFakeId(cart.oSalesItemList));
      }),
      catchError((error: HttpErrorResponse) => {
        const user: User | undefined = this.authService.getAuthUser();
        if (user) {
          this.activateRecentOrCreateNew(user).pipe(take(1)).subscribe();
        }
        return throwError(() => error);
      }),
      finalize(() => {
        this.cartHeaderStore.setLoading(false);
        this.cartItemStore.setLoading(false);
      }),
    );
  }

  createCartHeader(cartHeader: T): Observable<T> {
    return of(undefined).pipe(
      tap(() => this.cartHeaderStore.setLoading(true)),
      switchMap(() =>
        this.iif<T>(
          this.cartHttpService.createCartHeader(cartHeader),
          this.cartLocalService.createCartHeader(cartHeader),
          this.cartGuestService.createCartHeader(cartHeader),
        ),
      ),
      tap((cartHeader: T) => this.cartHeaderStore.add(cartHeader)),
      finalize(() => this.cartHeaderStore.setLoading(false)),
    );
  }

  updateCartHeader(cartHeader: T): Observable<Cart<T, R> | undefined> {
    return of(undefined).pipe(
      tap(() => {
        this.cartHeaderStore.setLoading(true);
        this.cartItemStore.setLoading(true);
      }),
      switchMap(() =>
        this.iif<Cart<T, R> | undefined>(
          this.cartHttpService.updateCartHeader(cartHeader),
          this.cartLocalService.updateCartHeader(cartHeader),
          this.cartGuestService.updateCartHeader(cartHeader),
        ),
      ),
      tap((cart: Cart<T, R> | undefined) => {
        if (cart) {
          this.cartHeaderStore.upsert(cart.oSalesMaster.lngOrderID, cart.oSalesMaster);
          this.cartItemStore.upsertMany(this.generateCartItemFakeId(cart.oSalesItemList));
        }
      }),
      finalize(() => {
        this.cartHeaderStore.setLoading(false);
        this.cartItemStore.setLoading(false);
      }),
    );
  }

  deleteCart(cartId: number | ID): Observable<void> {
    return of(undefined).pipe(
      tap(() => {
        this.cartHeaderStore.setLoading(true);
        this.cartItemStore.setLoading(true);
      }),
      switchMap(() =>
        this.iif<void>(
          this.cartHttpService.deleteCart(cartId),
          this.cartLocalService.deleteCart(cartId),
          this.cartGuestService.deleteCart(cartId),
        ),
      ),
      tap(() => {
        this.cartHeaderStore.remove((header: T) => header.lngOrderID.toString() === cartId.toString());
        this.cartItemStore.remove((item: R) => item.lngOrderID.toString() === cartId.toString());
      }),
      finalize(() => {
        this.cartHeaderStore.setLoading(false);
        this.cartItemStore.setLoading(false);
      }),
    );
  }

  @TrackMethod<PurchaseEvent>({
    provide: ([header, items]: any) => {
      return {
        name: 'purchase',
        payload: {
          ecommerce: {
            transaction_id: header.lngOrderID.toString(),
            currency: items[0].oArticle.oPriceInfo?.sCurrencyCode,
            value: items.reduce((last: number, current: R) => last + current.decItemNetAmountFC, 0),
            items: items.map((item: R) => ({
              item_id: item.sArticleID.toString(),
              item_name: item.sArticleName,
              price: item.decItemNetAmountFC,
              ...GoogleTagManagerHelper.hierarchicalCategoriesToItemCategories(
                item.oArticle.oProductInfo?.length ? item.oArticle.oProductInfo[0].listHierarchicalCategories : [],
              ),
              quantity: item.decQuantityOrdered,
            })),
          },
        },
      };
    },
  })
  submitCart(header: T, _: R[], user: User, createOffer = false): Observable<T> {
    return of(undefined).pipe(
      tap(() => {
        this.cartHeaderStore.setLoading(true);
        this.cartItemStore.setLoading(true);
      }),
      switchMap(() =>
        this.iif<any>(
          this.cartHttpService.submitCart(header, undefined, undefined, createOffer),
          this.cartLocalService.submitCart(),
          this.cartGuestService.submitCart(header),
        ),
      ),
      tap(() => {
        this.cartHeaderStore.remove(header.lngOrderID);
        this.cartItemStore.remove((item: R) => item.lngOrderID === header.lngOrderID);
      }),
      switchTap(() => this.activateRecentOrCreateNew(user)),
      finalize(() => {
        this.cartHeaderStore.setLoading(false);
        this.cartItemStore.setLoading(false);
      }),
    );
  }

  changeActiveCart(cartId: number | ID): Observable<Cart<T, R>> {
    return of(undefined).pipe(
      tap(() => {
        this.cartHeaderStore.setLoading(true);
        this.cartItemStore.setLoading(true);
      }),
      switchMap(() => this.cartHttpService.changeActiveCart(cartId)),
      tap((cart: Cart<T, R>) => {
        this.cartHeaderStore.upsert(cart.oSalesMaster.lngOrderID, cart.oSalesMaster);
        this.cartItemStore.upsertMany(this.generateCartItemFakeId(cart.oSalesItemList));
        this.cartHeaderStore.setActive(cart.oSalesMaster.lngOrderID);
      }),
      switchTap((cart: Cart<T, R>) => this.authService.updateAuthUser({ lngActiveCartID: cart.oSalesMaster.lngOrderID })),
      finalize(() => {
        this.cartHeaderStore.setLoading(false);
        this.cartItemStore.setLoading(false);
      }),
    );
  }

  @TrackMethod<AddToCartEvent>({
    provide: ([cartItem]: any) => {
      const quantity: number = cartItem.decQuantityOrdered || 1;

      return {
        name: 'add_to_cart',
        payload: {
          ecommerce: {
            currency: '', // TODO get currency
            value: GoogleTagManagerHelper.articlePrice(cartItem) * quantity,
            items: [
              {
                item_id: cartItem.sArticleID.toString(),
                item_name: cartItem.sArticleName,
                price: cartItem.decItemNetAmountFC,
                ...GoogleTagManagerHelper.hierarchicalCategoriesToItemCategories(
                  cartItem.oArticle.oProductInfo?.length ? cartItem.oArticle.oProductInfo[0].listHierarchicalCategories : [],
                ),
                quantity,
              },
            ],
          },
        },
      };
    },
  })
  @Hook<WorkflowCreateHook<'CreateCartItemHook'>, Observable<Cart<T, R>>>({
    id: { type: 'WorkflowCreateHook', entity: 'CreateCartItemHook' },
    returnType: HookReturnType.OBSERVABLE,
  })
  createCartItem(cartItem: S): Observable<Cart<T, R>> {
    const activeCartId = this.cartHeaderQuery.getActiveId() as ID;
    return of(undefined).pipe(
      tap(() => {
        this.cartHeaderStore.setLoading(true);
        this.cartItemStore.setLoading(true);
      }),
      switchMap(() =>
        this.iif<Cart<T, R>>(
          this.cartHttpService.createCartItem(cartItem, activeCartId),
          this.cartLocalService.createCartItem(cartItem, activeCartId),
          this.cartGuestService.createCartItem(cartItem, activeCartId),
        ),
      ),
      tap((cart) => {
        this.cartHeaderStore.upsert(cart.oSalesMaster?.lngOrderID, cart.oSalesMaster);
        this.cartItemStore.upsertMany(this.generateCartItemFakeId(cart.oSalesItemList));
      }),
      first(),
      finalize(() => {
        this.cartHeaderStore.setLoading(false);
        this.cartItemStore.setLoading(false);
      }),
    );
  }

  @Hook<WorkflowUpdateHook<'UpdateCartItemHook'>, Observable<Cart<T, R>>>({
    id: { type: 'WorkflowUpdateHook', entity: 'UpdateCartItemHook' },
    returnType: HookReturnType.OBSERVABLE,
  })
  updateCartItem(cartItem: R): Observable<Cart<T, R>> {
    return of(undefined).pipe(
      tap(() => {
        this.cartHeaderStore.setLoading(true);
        this.cartItemStore.setLoading(true);
      }),
      switchMap(() =>
        this.iif<Cart<T, R>>(
          this.cartHttpService.updateCartItem(cartItem),
          this.cartLocalService.updateCartItem(cartItem),
          this.cartGuestService.updateCartItem(cartItem),
        ),
      ),
      tap((cart: Cart<T, R>) => {
        this.cartHeaderStore.upsert(cart.oSalesMaster.lngOrderID, cart.oSalesMaster);
        this.cartItemStore.upsertMany(this.generateCartItemFakeId(cart.oSalesItemList));
      }),
      finalize(() => {
        this.cartHeaderStore.setLoading(false);
        this.cartItemStore.setLoading(false);
      }),
    );
  }

  @TrackMethod<RemoveFromCartEvent>({
    provide: ([cartItem]: any) => {
      const quantity: number = cartItem.decQuantityOrdered || 1;

      return {
        name: 'remove_from_cart',
        payload: {
          ecommerce: {
            currency: '', // TODO get currency
            value: GoogleTagManagerHelper.articlePrice(cartItem) * quantity,
            items: [{ ...GoogleTagManagerHelper.articleToItem(cartItem), quantity }],
          },
        },
      };
    },
  })
  @Hook<WorkflowDeleteHook<'DeleteCartItemHook'>, Observable<Cart<T, R>>>({
    id: { type: 'WorkflowDeleteHook', entity: 'DeleteCartItemHook' },
    returnType: HookReturnType.OBSERVABLE,
  })
  deleteCartItem(cartItem: R): Observable<Cart<T, R>> {
    return of(undefined).pipe(
      tap(() => {
        this.cartHeaderStore.setLoading(true);
        this.cartItemStore.setLoading(true);
      }),
      switchMap(() =>
        this.iif<Cart<T, R>>(
          this.cartHttpService.deleteCartItem(cartItem),
          this.cartLocalService.deleteCartItem(cartItem),
          this.cartGuestService.deleteCartItem(cartItem),
        ),
      ),
      tap((cart: Cart<T, R>) => {
        this.cartItemStore.remove((item: R) => item.lngOrderID === cart.oSalesMaster.lngOrderID);
        this.cartHeaderStore.upsert(cart.oSalesMaster.lngOrderID, cart.oSalesMaster);
        this.cartItemStore.upsertMany(this.generateCartItemFakeId(cart.oSalesItemList));
      }),
      finalize(() => {
        this.cartHeaderStore.setLoading(false);
        this.cartItemStore.setLoading(false);
      }),
    );
  }

  setActiveId(user?: User): Observable<number | ID> {
    return of(undefined).pipe(
      tap(() => this.cartHeaderStore.setLoading(true)),
      switchMap(() =>
        this.iif<number | ID>(
          this.cartHttpService.setActiveId(user),
          this.cartLocalService.setActiveId(user),
          this.cartGuestService.setActiveId(user),
        ),
      ),
      tap((id: number | ID) => this.cartHeaderStore.setActive(id)),
      finalize(() => this.cartHeaderStore.setLoading(false)),
    );
  }

  /**
   * Converts any locally saved cart items to a newly created remote cart
   * and removes the local items in that process
   */
  convertLocalToRemote(_: User): Observable<Cart<T, R>> {
    const end$ = new Subject<boolean>();

    return this.cartLocalService.getCartById(CartLocalService.ACTIVE_CART_ID).pipe(
      first(),
      switchMap((cart: Cart<T, R>) =>
        iif(
          // no or empty cart
          () => !cart?.oSalesItemList?.length,
          // true
          of(cart),
          // false
          combineLatest([of(cart), this.createCartHeader(cart.oSalesMaster)]).pipe(
            switchTap(([_, header]: [Cart<T, R>, T]) => this.changeActiveCart(header.lngOrderID)),
            mergeMap(([cart, _]: [Cart<T, R>, T]) => {
              const creationObservables: Observable<any>[] = [];
              creationObservables.push(this.cartLocalService.deleteCart(CartLocalService.ACTIVE_CART_ID));
              for (const cartItem of cart.oSalesItemList) {
                creationObservables.push(this.cartLocalService.deleteCartItem(cartItem));
                creationObservables.push(
                  this.createCartItem({
                    oArticle: cartItem.oArticle,
                    decQuantity: cartItem.decQuantity,
                    sQuantityUnit: cartItem.sQuantityUnit,
                    sArticleID: cartItem.sArticleID,
                    sArticleName: cartItem.sArticleName,
                    sItemText: cartItem.sItemText,
                    emitHook: false,
                  } as S),
                );
              }

              // end stream, so toArray() can work
              creationObservables.push(
                of(undefined).pipe(
                  tap(() => {
                    end$.next(true);
                    end$.complete();
                  }),
                ),
              );

              return merge(creationObservables);
            }),
            mergeMap(($req) => $req, 1),
            takeUntil(end$),
            toArray(),
            map((carts: Cart<T, R>[]) => carts[0]),
          ),
        ),
      ),
    );
  }

  activateRecentOrCreateNew(user: User): Observable<T> {
    return this.getCartHeaders().pipe(
      switchMap((list: T[]) =>
        iif(
          // user has already carts
          () => list.length > 0,
          // true
          of(list.sort((a: T, b: T) => b.dtEntryDate - a.dtEntryDate)[0]),
          // false
          this.createEmptyList(user),
        ),
      ),
      switchTap((header: T) => this.changeActiveCart(header.lngOrderID)),
    );
  }

  clearCartHeader() {
    this.cartHeaderStore.reset();
  }

  @Hook<WorkflowCreateHook<'CreateOrderFromOfferHook'>, Observable<Cart<T, R>>>({
    id: { type: 'WorkflowCreateHook', entity: 'CreateOrderFromOfferHook' },
    returnType: HookReturnType.OBSERVABLE,
  })
  acceptOffer(lngOrderID: number | ID, sOrderType: string): Observable<Cart<T, R>> {
    return this.cartHttpService.reorder(lngOrderID, sOrderType).pipe(filter((cart: Cart<T, R>) => !!cart));
  }

  @Hook<WorkflowCreateHook<'CreateCartFromOrderHook'>, Observable<Cart<T, R>>>({
    id: { type: 'WorkflowCreateHook', entity: 'CreateCartFromOrderHook' },
    returnType: HookReturnType.OBSERVABLE,
  })
  reorderOrder(lngOrderID: number | ID, sOrderType: string): Observable<Cart<T, R>> {
    return of(undefined).pipe(
      tap(() => {
        this.cartHeaderStore.setLoading(true);
        this.cartItemStore.setLoading(true);
      }),
      switchMap(() => this.cartHttpService.reorder(lngOrderID, sOrderType)),
      filter((cart: Cart<T, R>) => !!cart),
      tap((cart: Cart<T, R>) => {
        this.cartHeaderStore.upsert(cart.oSalesMaster.lngOrderID, cart.oSalesMaster);
        this.cartItemStore.upsertMany(this.generateCartItemFakeId(cart.oSalesItemList));
      }),
      switchTap((cart: Cart<T, R>) => {
        const user = this.authService.getAuthUser();
        return this.userService
          .update<User>(user?.lngContactID, {
            ...user,
            lngActiveCartID: cart.oSalesMaster.lngOrderID,
          })
          .pipe(
            switchMap((user: User) => {
              this.cartHeaderStore.setActive(user.lngActiveCartID);
              return this.authService.updateAuthUser({ lngActiveCartID: user.lngActiveCartID });
            }),
          );
      }),
      finalize(() => {
        this.cartHeaderStore.setLoading(false);
        this.cartItemStore.setLoading(false);
      }),
    );
  }

  protected createEmptyList(user: User): Observable<T> {
    // when injecting transloco scope, it is the wrong one, so it is added hard-coded here
    const scope = 'cart';

    return this.transloco
      .selectTranslate(
        'domain.new-cart.name',
        { date: this.translocoLocale.localizeDate(Date.now(), undefined, { dateStyle: 'medium', timeStyle: 'short' }) },
        scope,
      )
      .pipe(
        switchMap((name: string) =>
          this.createCartHeader(
            createCartHeader({
              lngCustomerID: user.lngCustomerID,
              lngContactID: user.lngContactID as number,
              sCartName: name,
              sIContact: `${user.sFormOfAdress} ${user.sFirstName} ${user.sLastName}`,
            }),
          ),
        ),
      );
  }

  protected generateCartItemFakeId(cartItems: R[] = []): R[] {
    return cartItems.map((item: R) => {
      return { ...item, fakeId: [item.lngOrderID, item.shtFixedItemID].join(',') };
    });
  }

  protected iif<T>(userCart: Observable<T>, localCart: Observable<T>, guestCart: Observable<T>): Observable<T> {
    return combineLatest([this.authService.authUser$, this.paramQuery.params$]).pipe(
      filter(([, params]) => !!params),
      switchMap(([authUser, params]) => (authUser ? userCart : params.sP48GuestCartMode === 'enventa_offer' ? guestCart : localCart)),
      first(),
    );
  }

  printCart(cartHeader: T): Observable<any> {
    return this.iif<T>(
      this.cartHttpService.printCart(cartHeader),
      this.cartLocalService.printCart(cartHeader),
      this.cartGuestService.printCart(cartHeader),
    ).pipe(
      filter((res) => !!res),
      tap((res) => {
        this.saveFileAs(res as any, `${cartHeader.sCartName}_${cartHeader.lngOrderID.toString()}.pdf`);
      }),
    );
  }

  saveFileAs(fileBlob: any, fileName: string) {
    const file = new Blob([fileBlob], { type: 'application/pdf' });
    saveAs(file, fileName);
  }
}
