import { Component, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ACCESS_ID, AuthService, User } from '@lobos/library';
import { TranslocoService } from '@ngneat/transloco';
import { LoginDialogService } from '../../../services/login/login-dialog.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  isLoggedIn$: Observable<boolean>;
  accessId: typeof ACCESS_ID = ACCESS_ID;
  status: boolean = false;
  environment = environment;

  currentUser$: Observable<User | undefined> = this.authService.authUser$;

  @Output() closeMobileNav: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private translocoService: TranslocoService,
    private authService: AuthService,
    private loginDialogService: LoginDialogService,
    private router: Router,
  ) {
    this.isLoggedIn$ = this.authService.isLoggedIn$;
  }

  openLoginDialog() {
    this.loginDialogService.open();
  }

  public async logout() {
    await this.authService.logout();
    await this.router.navigate(['/']);
  }
}
