import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { UrlHelperService } from '../../core';
import { CmsUiDynamicContentMenu, CmsUiDynamicContentMenuLevel } from '../model/cms.models';
import { CmsUiDynamicContentQuery } from '../query/cms.query';
import { Breadcrumb, BreadcrumbsResolver } from '../../breadcrumb';

@Injectable({
  providedIn: 'root',
})
export class PageBreadcrumbResolver extends BreadcrumbsResolver {
  constructor(private cmsQuery: CmsUiDynamicContentQuery, private urlHelper: UrlHelperService) {
    super();
  }

  public override resolve(): Observable<Breadcrumb[]> {
    return this.cmsQuery.cmsMainMenu$.pipe(
      first(),
      map((menu: CmsUiDynamicContentMenu) => this.traverse(menu)),
    );
  }

  private traverse(menu: any, level = 1): Breadcrumb[] {
    const selector = `level_${level}`;
    let matches: Breadcrumb[] = [];

    if (!menu[selector]) {
      return matches;
    }

    const currentLevelItems: CmsUiDynamicContentMenuLevel[] = menu[selector];
    for (const item of currentLevelItems) {
      if (this.pathMatches(item)) {
        return [this.menuItemToBreadcrumb(item)];
      }

      const nextLevelMatches = this.traverse(item as CmsUiDynamicContentMenu, level + 1);
      if (nextLevelMatches.length) {
        matches = [...matches, this.menuItemToBreadcrumb(item), ...nextLevelMatches];
      }
    }

    return matches;
  }

  private menuItemToBreadcrumb(item: CmsUiDynamicContentMenuLevel): {
    path: string;
    label: string;
    text: string;
    translate: boolean;
    url: string;
  } {
    return {
      label: item.title,
      translate: false,
      url: this.urlHelper.localizeUrl(item.slug),
      path: this.urlHelper.localizeUrl(item.slug),
      text: item.title,
    };
  }

  private pathMatches(item: CmsUiDynamicContentMenuLevel): boolean {
    return this.urlHelper.getPath().substring(3) === item.slug;
  }
}
