import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Language } from '../model/language.model';
import { LanguageQuery } from '../query/language.query';

@Pipe({
  name: 'language',
})
export class LanguageNamePipePipe implements PipeTransform {
  constructor(protected languageQuery: LanguageQuery<Language>) {}

  transform(value: number): Observable<string> {
    return this.languageQuery.selectAll().pipe(
      map((languages: Language[]) => {
        if (languages.length > 0 && value) {
          return languages.find((lang) => lang.shtLanguageID === value)!.sLanguageName;
        } else {
          return '';
        }
      }),
    );
  }
}
