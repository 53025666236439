import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { FACTORY_ATTESTATION_CONFIG, FactoryAttestationConfig } from './config/factory-attestation.config';

@NgModule({
  imports: [CommonModule, TranslocoModule],
})
export class FactoryAttestationModule {
  static forRoot(config: FactoryAttestationConfig): ModuleWithProviders<FactoryAttestationModule> {
    return {
      ngModule: FactoryAttestationModule,
      providers: [
        {
          provide: FACTORY_ATTESTATION_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
