import { Pipe, PipeTransform } from '@angular/core';
import { UrlHelperService } from '../url/url-helper.service';

@Pipe({ name: 'urlEncoding' })
export class UrlEncodingPipe implements PipeTransform {
  constructor(private urlHelperService: UrlHelperService) {}

  transform(path: string): string {
    return this.urlHelperService.normalize(path);
  }
}
