import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { AuthService } from '../../auth';
import { Article } from '../../catalog';
import { AddToWishlistEvent, GoogleTagManagerHelper, TrackMethod } from '../../tracking';
import { User, UserService } from '../../user';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, iif, merge, Observable, of, Subject } from 'rxjs';
import { filter, first, map, mergeMap, switchMap, take, takeUntil, tap, toArray } from 'rxjs/operators';
import { FavoriteAdapterInterface } from '../model/favorite-adapter.interface';
import { createFavoriteList, FavoriteList } from '../model/favorite-list.model';
import { FavoriteItemStore } from '../store/favorite-item.store';
import { FavoriteListStore } from '../store/favorite-list.store';
import { FavoriteHttpService } from './favorite-http.service';
import { FavoriteLocalService } from './favorite-local.service';
import { FavoriteItem } from '../model/favorite-item.model';
import { Hook, HookReturnType, WorkflowCreateHook, WorkflowDeleteHook } from '../../core';

// @dynamic
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class FavoriteService<T extends FavoriteList, R extends FavoriteItem> implements FavoriteAdapterInterface<T, R> {
  /**
   * When injecting transloco scope, it is the wrong one, so it is added hard-coded here
   */
  static readonly TRANSLOCO_SCOPE = 'favorite';

  constructor(
    private transloco: TranslocoService,
    private translocoLocale: TranslocoLocaleService,
    private authService: AuthService,
    private favoriteHttpService: FavoriteHttpService<T, R>,
    private favoriteLocalService: FavoriteLocalService<T, R>,
    private favoriteItemStore: FavoriteItemStore<R>,
    private favoriteListStore: FavoriteListStore<T>,
    private userService: UserService,
  ) {}

  @TrackMethod<AddToWishlistEvent>({
    provide: ([favoriteItem]: any) => {
      const article: Article = favoriteItem.oArticle;
      const quantity: number = favoriteItem.decQuantity || 1;

      return {
        name: 'add_to_wishlist',
        payload: {
          ecommerce: {
            currency: article.oPriceInfo?.sCurrencyCode,
            value: GoogleTagManagerHelper.articlePrice(article) * quantity,
            items: [{ ...GoogleTagManagerHelper.articleToItem(article), quantity }],
          },
        },
      };
    },
  })
  @Hook<WorkflowCreateHook<'CreateFavoriteItemHook'>, Observable<R>>({
    id: { type: 'WorkflowCreateHook', entity: 'CreateFavoriteItemHook' },
    returnType: HookReturnType.OBSERVABLE,
  })
  createFavoriteItem(favoriteItem: R, favoriteListId: ID | string): Observable<R> {
    return this.iif(
      this.favoriteHttpService.createFavoriteItem(favoriteItem, favoriteListId),
      this.favoriteLocalService.createFavoriteItem(favoriteItem),
    ).pipe(
      tap((favoriteItemResponse: R) => this.favoriteItemStore.add(favoriteItemResponse)),
      map(() => favoriteItem),
    );
  }

  @Hook<WorkflowDeleteHook<'DeleteFavoriteItemHook'>, Observable<void>>({
    id: { type: 'WorkflowDeleteHook', entity: 'DeleteFavoriteItemHook' },
    returnType: HookReturnType.OBSERVABLE,
  })
  deleteFavoriteItem(favoriteItem: R): Observable<void> {
    return this.iif(
      this.favoriteHttpService.deleteFavoriteItem(favoriteItem),
      this.favoriteLocalService.deleteFavoriteItem(favoriteItem),
    ).pipe(tap(() => this.favoriteItemStore.remove(favoriteItem.fakeId)));
  }

  createFavoriteList(favoriteList: T): Observable<T> {
    return this.iif(
      this.favoriteHttpService.createFavoriteList(favoriteList),
      this.favoriteLocalService.createFavoriteList(favoriteList),
    ).pipe(tap((favoriteListResponse: T) => this.favoriteListStore.add(favoriteListResponse)));
  }

  deleteFavoriteList(favoriteList: T): Observable<void> {
    return this.iif(
      this.favoriteHttpService.deleteFavoriteList(favoriteList),
      this.favoriteLocalService.deleteFavoriteList(favoriteList),
    ).pipe(tap(() => this.favoriteListStore.remove(favoriteList.gListID)));
  }

  getFavoriteItems(favoriteListId: ID | string): Observable<R[]> {
    return this.iif(
      this.favoriteHttpService.getFavoriteItems(favoriteListId),
      this.favoriteLocalService.getFavoriteItems(favoriteListId),
    ).pipe(tap((favoriteItems: R[]) => this.favoriteItemStore.upsertMany(this.generateFavoriteItemFakeId(favoriteItems))));
  }

  getFavoriteLists(): Observable<T[]> {
    return this.iif(this.favoriteHttpService.getFavoriteLists(), this.favoriteLocalService.getFavoriteLists()).pipe(
      tap((favoriteLists: T[]) => this.favoriteListStore.upsertMany(favoriteLists)),
    );
  }

  getFavoriteListById(favoriteListId: ID): Observable<T | undefined> {
    return this.iif(
      this.favoriteHttpService.getFavoriteListById(favoriteListId),
      this.favoriteLocalService.getFavoriteListById(favoriteListId),
    ).pipe(
      tap((favoriteList: T | undefined) => {
        if (favoriteList) {
          this.favoriteListStore.upsert(favoriteList.gListID, favoriteList);
        }
      }),
    );
  }

  updateFavoriteItem(favoriteItem: R): Observable<R | undefined> {
    return this.iif(
      this.favoriteHttpService.updateFavoriteItem(favoriteItem),
      this.favoriteLocalService.updateFavoriteItem(favoriteItem),
    ).pipe(
      tap((favoriteItemResponse: R | undefined) => {
        if (favoriteItemResponse) {
          this.favoriteItemStore.update([favoriteItemResponse.gListID, favoriteItemResponse.shtItemID].join(','), favoriteItemResponse);
        }
      }),
    );
  }

  updateFavoriteList(favoriteList: T): Observable<T> {
    return this.iif(
      this.favoriteHttpService.updateFavoriteList(favoriteList),
      this.favoriteLocalService.updateFavoriteList(favoriteList),
    ).pipe(tap((favoriteList: T) => this.favoriteListStore.update(favoriteList.gListID, favoriteList)));
  }

  setActiveFavoriteId(id: ID): void {
    this.authService
      .getAuthUserAsync()
      .pipe(
        filter((user: User | undefined) => !!user),
        tap((user: User | undefined) => {
          this.userService
            .update<User>(user?.lngContactID, { ...user, gActiveBookmarkListID: id })
            .pipe(
              switchMap((user: User) => this.authService.updateAuthUser({ gActiveBookmarkListID: user.gActiveBookmarkListID })),
              untilDestroyed(this),
              take(1),
            )
            .subscribe();
          this.favoriteListStore.setActive(id);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  setActiveFavorite(user?: User): Observable<ID | string | undefined> {
    return this.iif<ID | string | undefined>(
      this.favoriteHttpService.setActiveFavorite(user),
      this.favoriteLocalService.setActiveFavorite(user),
    ).pipe(
      tap((id: ID | string | undefined) => {
        if (id) {
          this.favoriteListStore.setActive(id);
        }
      }),
    );
  }

  /**
   * Converts any locally saved favorites to a newly created remote favorites
   * and removes the local items in that process
   */
  convertLocalToRemote(): Observable<R[]> {
    const end$ = new Subject<boolean>();

    return this.favoriteLocalService.getFavoriteItems(undefined).pipe(
      first(),
      switchMap((favorites: R[]) =>
        iif(
          // no or empty favorites
          () => !favorites?.length,
          // true
          of(favorites),
          // false
          combineLatest([of(favorites), this.createEmptyList()]).pipe(
            tap(([_, list]: [R[], T]) => this.setActiveFavoriteId(list.gListID)),
            mergeMap(([favorites, list]: [R[], T]) => {
              const creationObservables: Observable<any>[] = [];
              for (const favorite of favorites) {
                creationObservables.push(this.favoriteLocalService.deleteFavoriteItem(favorite));
                creationObservables.push(this.createFavoriteItem({ ...favorite, gListID: list.gListID }, list.gListID));
              }

              // end stream, so toArray() can work
              creationObservables.push(
                of(undefined).pipe(
                  tap(() => {
                    end$.next(true);
                    end$.complete();
                  }),
                ),
              );

              return merge(creationObservables);
            }),
            mergeMap(($req) => $req, 1),
            takeUntil(end$),
            filter((f: R) => !!f),
            toArray(),
          ),
        ),
      ),
    );
  }

  activateRecentOrCreateNew(): Observable<T> {
    return this.getFavoriteLists().pipe(
      switchMap((list: T[]) =>
        iif(
          // user has already favorites
          () => list.length > 0,
          // true
          of(list.sort((a: T, b: T) => b.dtP48EntryDate - a.dtP48EntryDate)[0]),
          // false
          this.createEmptyList(),
        ),
      ),
      tap((list: T) => this.setActiveFavoriteId(list.gListID)),
    );
  }

  clearFavoriteLists() {
    this.favoriteListStore.reset();
  }

  private createEmptyList(): Observable<T> {
    return this.transloco
      .selectTranslate(
        'domain.new-list.name',
        { date: this.translocoLocale.localizeDate(Date.now(), undefined, { dateStyle: 'medium', timeStyle: 'short' }) },
        FavoriteService.TRANSLOCO_SCOPE,
      )
      .pipe(switchMap((name: string) => this.createFavoriteList(createFavoriteList<T>({ sListname: name }))));
  }

  private iif<T>(positive: Observable<T>, negative: Observable<T>): Observable<T> {
    return this.authService.authUser$.pipe(
      map((user: User | undefined) => !!user),
      switchMap((status: boolean) => (status ? positive : negative)),
      first(),
    );
  }

  private generateFavoriteItemFakeId(favoriteItems: R[]): R[] {
    return favoriteItems.map((item: R) => {
      return { ...item, fakeId: [item.gListID, item.shtItemID].join(',') };
    });
  }
}
