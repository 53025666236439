import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TrackingFactory } from '../../services/tracking.factory';
import { GOOGLE_TAG_MANAGER_CONFIG, GoogleTagManagerConfig } from './config/google-tag-manager.config';
import { GoogleTagManagerAdapter } from './services/google-tag-manager.adapter';

@NgModule({
  imports: [CommonModule],
})
export class GoogleTagManagerAdapterModule {
  constructor(private trackingFactory: TrackingFactory, private gtmAdapter: GoogleTagManagerAdapter) {
    trackingFactory.register(gtmAdapter);
  }

  static forRoot(config: GoogleTagManagerConfig): ModuleWithProviders<GoogleTagManagerAdapterModule> {
    return {
      ngModule: GoogleTagManagerAdapterModule,
      providers: [
        {
          provide: GOOGLE_TAG_MANAGER_CONFIG,
          useValue: { ...config },
        },
      ],
    };
  }
}
