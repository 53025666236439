import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UrlEncodingPipe } from './url-encoding.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [UrlEncodingPipe],
  exports: [UrlEncodingPipe],
})
export class UrlEncodingModule {}
