import { Injectable } from '@angular/core';
import { ConfigurationParam } from '../interfaces/steel-configuration-param';
import { SteelConfigStore } from '../store/steel-configuration.store';
import { HttpClient } from '@angular/common/http';
import { SteelConfiguration } from '../models/steel-configuration.model';
import { Observable } from 'rxjs';
import { SteelArticle } from '../models/steel-article.model';

@Injectable({ providedIn: 'root' })
export class SteelConfigService<T extends SteelConfiguration> {
  constructor(protected store: SteelConfigStore<T>, protected httpClient: HttpClient) {}

  createConfiguration(article: SteelArticle): Observable<T> {
    const configurationParam: ConfigurationParam = {
      sArticleID: article.sArticleID.toString(),
      decBO3Factor1: 0,
      decBO3Factor2: 0,
      decBO3Factor3: 0,
      decBO3QuantityInput: 1,
      sBO3QuantityUnitInput: article.oUnitColl[0]?.sQuantityUnitInput,
    };
    return this.httpClient.post<T>('/p48apihost/steel-configuration', configurationParam);
  }

  updateConfiguration(steelConfig: T): Observable<T> {
    return this.httpClient.put<T>('/p48apihost/steel-configuration', steelConfig);
  }
}
