import { createFavoriteItem, FavoriteItem } from '../../favorite';
import { SteelArticle } from './steel-article.model';

export interface SteelFavoriteItemModel extends FavoriteItem {
  oArticle: SteelArticle;
  sB03QuantityUnitInput: string;
}

/**
 * A factory function that creates SteelFavoriteItem
 */
export function createSteelFavoriteItem<T extends SteelFavoriteItemModel, C extends SteelArticle>({
  gListID = '',
  decQuantity = 1,
  oArticle = undefined,
  shtItemID = 0,
  fakeId = '',
  sB03QuantityUnitInput = '',
}: {
  gListID?: string;
  decQuantity?: number;
  oArticle?: C;
  shtItemID?: number;
  fakeId?: string;
  sB03QuantityUnitInput?: string;
}): T {
  return {
    ...createFavoriteItem({ gListID, decQuantity, oArticle, shtItemID, fakeId }),
    sB03QuantityUnitInput,
  };
}
