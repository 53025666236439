import { Inject, Injectable } from '@angular/core';
import { ArticleService, ArticleStore, CATALOG_CONFIG, CatalogConfig } from '../../catalog';
import { ElasticSearchService } from '../../core';
import { SteelArticle } from '../models/steel-article.model';

/**
 * A service to manage steel article entities
 */
@Injectable({ providedIn: 'root' })
export class SteelArticleService<T extends SteelArticle> extends ArticleService<T> {
  constructor(store: ArticleStore<T>, elasticSearch: ElasticSearchService, @Inject(CATALOG_CONFIG) catalogConfig: CatalogConfig) {
    super(store, elasticSearch, catalogConfig);
  }
}
