import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LanguageNamePipePipe } from './pipes/language-name-pipe.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [LanguageNamePipePipe],
  exports: [LanguageNamePipePipe],
})
export class LanguageModule {}
